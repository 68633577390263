import React from 'react'
import SubHeading from '../components/typography/SubHeading'
import Layout from '../layout/Layout'
import BannerImage from '../images/banner-faq.jpg'

interface Props {}

const Page: React.FC<Props> = () => {
    return (
        <Layout bannerImage={BannerImage}>
            <SubHeading>What to considere</SubHeading>
        </Layout>
    )
}

export default Page
